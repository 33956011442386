import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    attrs: {
      "title": _vm.$route.name === 'NewCard' ? 'Új kártya létrehozása' : 'Kártya szerkesztése'
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_vm.hasRight('CARD_WRITE') && _vm.hasFeature('CARD_CREATION') ? _c('title-row-button', {
          on: {
            "click": _vm.save
          }
        }, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-check")]), _vm._v(" Mentés ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }), _vm.loading ? _c('loading-overlay') : _vm._e(), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-badge-account-horizontal")]), _vm._v("Alapadatok")], 1), _c(VCardText, [_c('div', {
    staticClass: "d-flex"
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Kártyabirtokos",
      "error-messages": _vm.errors.owner_id,
      "items": _vm.cardOwners,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "hint": _vm.isOwnerIdMutable ? '' : 'Nem módosítható',
      "persistent-hint": "",
      "readonly": !_vm.isOwnerIdMutable
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }]),
    model: {
      value: _vm.form.owner_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "owner_id", $$v);
      },
      expression: "form.owner_id"
    }
  }), _vm.$route.name === 'NewCard' ? _c(VBtn, {
    staticClass: "ml-1 rounded",
    attrs: {
      "color": "secondary",
      "depressed": "",
      "tile": "",
      "fab": "",
      "to": {
        name: 'NewCardOwner'
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, [_c('v-masked-text-field', {
    attrs: {
      "label": "Kártyaszám",
      "mask": _vm.$config.inputMasks.cardNumber,
      "filled": ""
    },
    model: {
      value: _vm.form.card_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "card_number", $$v);
      },
      expression: "form.card_number"
    }
  })], 1), _c(VCol, [_c('v-masked-text-field', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    attrs: {
      "label": "RFID",
      "mask": _vm.$config.inputMasks.rfId,
      "persistent-hint": "",
      "placeholder": "Érintse a kártyát az olvasóhoz",
      "filled": "",
      "error-messages": _vm.errors.rf_id,
      "hint": _vm.form.status.name_ && _vm.form.status.name_ !== 'printReady' ? 'Nem módosítható' : ''
    },
    model: {
      value: _vm.form.rf_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rf_id", $$v);
      },
      expression: "form.rf_id"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_vm.form.element_id != 0 ? _c(VCol, [_c(VTextField, {
    attrs: {
      "label": "Státusz",
      "value": _vm.form.status.name,
      "readonly": "",
      "hint": "Nem módosítható",
      "persistent-hint": "",
      "filled": ""
    }
  })], 1) : _vm._e(), _c(VCol, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasFeature('CARD_TEMPLATE'),
      expression: "hasFeature('CARD_TEMPLATE')"
    }]
  }, [_c(VAutocomplete, {
    attrs: {
      "label": "Sablon",
      "items": _vm.cardTemplates,
      "item-text": "name",
      "item-value": "id",
      "readonly": _vm.form.status.name_ && _vm.form.status.name_ !== 'printReady',
      "error-messages": _vm.errors.template_id,
      "hint": _vm.form.status.name_ && _vm.form.status.name_ !== 'printReady' ? 'Nem módosítható' : '',
      "persistent-hint": "",
      "filled": ""
    },
    on: {
      "change": function ($event) {
        _vm.showPrintIcon = false;
      }
    },
    model: {
      value: _vm.form.template_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "template_id", $$v);
      },
      expression: "form.template_id"
    }
  })], 1)], 1)], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-security")]), _vm._v("Hozzáférési szintek")], 1), _c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Megnevezés',
        value: 'name'
      }, {
        text: 'Lejárat',
        value: 'valid_to'
      }],
      "items": _vm.accessLevels,
      "item-value": "element_id",
      "item-text": "name",
      "unique-values": "",
      "blank-item-template": {
        element_id: 0
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref3) {
        var item = _ref3.item,
          items = _ref3.items;
        return [_c(VAutocomplete, {
          attrs: {
            "items": items,
            "item-text": "name",
            "item-value": "element_id",
            "placeholder": "Válassz",
            "hide-details": "",
            "filled": "",
            "dense": ""
          },
          model: {
            value: item.element_id,
            callback: function ($$v) {
              _vm.$set(item, "element_id", $$v);
            },
            expression: "item.element_id"
          }
        })];
      }
    }, {
      key: "item.valid_to",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "time-picker": false,
            "hide-details": "",
            "dense": "",
            "placeholder": "Nincs lejárat",
            "filled": ""
          },
          model: {
            value: item.valid_to,
            callback: function ($$v) {
              _vm.$set(item, "valid_to", $$v);
            },
            expression: "item.valid_to"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.access_levels,
      callback: function ($$v) {
        _vm.$set(_vm.form, "access_levels", $$v);
      },
      expression: "form.access_levels"
    }
  })], 1)], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-car")]), _vm._v("Rendszámok")], 1), _c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Rendszám',
        value: 'name'
      }],
      "items": _vm.form.plates,
      "item-value": "number",
      "item-text": "number",
      "hide-default-header": "",
      "blank-item-template": {
        value: ''
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [_c('v-masked-text-field', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "label": ("#" + (item.id || index + 1)),
            "error-messages": _vm.errors[("plate-" + index)],
            "filled": "",
            "dense": "",
            "hide-details": !item.active_season_ticket,
            "mask": _vm.$config.inputMasks.plateNumber
          },
          model: {
            value: item.number,
            callback: function ($$v) {
              _vm.$set(item, "number", $$v);
            },
            expression: "item.number"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.plates,
      callback: function ($$v) {
        _vm.$set(_vm.form, "plates", $$v);
      },
      expression: "form.plates"
    }
  })], 1)], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-history")]), _vm._v("Eseménynapló")], 1), _c(VCardText, [_vm.form.element_id ? _c('event-log', {
    attrs: {
      "log": _vm.form.log,
      "access-levels": _vm.accessLevels,
      "card-statuses": _vm.cardStatuses
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }