<template>
  <div>
    <!-- <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        color="grey"
        dark
        @click="$router.go(-1)"
        title="Vissza"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status.name_ !== 'permInactive'"
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        :disabled="loading"
        title="Mentés"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status.name_ === 'printReady' && showPrintIcon"
        v-bind="$config.buttonAttrs.floating"
        @click="print"
        :disabled="loading"
        title="Nyomtatás"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status.name_ === 'active'"
        v-bind="$config.buttonAttrs.floating"
        @click="suspend"
        :disabled="loading"
        title="Felfüggesztés"
      >
        <v-icon>mdi-account-clock</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status.name_ === 'active' || form.status.name_ === 'tempInactive'"
        v-bind="$config.buttonAttrs.floating"
        color="error darken-1"
        @click="deactivate"
        :disabled="loading"
        title="Végleges letiltás"
      >
        <v-icon>mdi-account-off</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status.name_ === 'tempInactive'"
        v-bind="$config.buttonAttrs.floating"
        @click="activate(false)"
        :disabled="loading"
        title="Aktiválás"
      >
        <v-icon>mdi-account-reactivate</v-icon>
      </v-btn>
      <v-btn
        v-if="form.status == 'idReading'"
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        :disabled="loading"
        title="Kártya beolvasása"
        dark
      >
        <v-icon>mdi-card-search</v-icon>
      </v-btn>
    </div> -->

    <title-row :title="$route.name === 'NewCard' ? 'Új kártya létrehozása' : 'Kártya szerkesztése'">
      <template #buttons>
        <title-row-button
          v-if="hasRight('CARD_WRITE') && hasFeature('CARD_CREATION')"
          @click="save"
        >
          <v-icon left>mdi-check</v-icon>
          Mentés
        </title-row-button>
      </template>
    </title-row>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12" :md="6">
        <v-card>
          <v-card-title><v-icon left>mdi-badge-account-horizontal</v-icon>Alapadatok</v-card-title>
          <v-card-text>
            <div class="d-flex">
              <v-autocomplete
                label="Kártyabirtokos"
                :error-messages="errors.owner_id"
                v-model="form.owner_id"
                :items="cardOwners"
                item-text="name"
                item-value="element_id"
                filled
                :hint="isOwnerIdMutable ? '' : 'Nem módosítható'"
                persistent-hint
                :readonly="!isOwnerIdMutable"
              >
                <template #selection="{ item }">
                  {{ item.name }}
                </template>
                <template #item="{ item }">
                  {{ item.name }}
                </template>
              </v-autocomplete>

              <v-btn
                v-if="$route.name === 'NewCard'"
                color="secondary"
                depressed
                tile
                fab
                class="ml-1 rounded"
                :to="{ name: 'NewCardOwner' }"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>

            <v-row dense>
              <v-col>
                <v-masked-text-field
                  label="Kártyaszám"
                  v-model="form.card_number"
                  :mask="$config.inputMasks.cardNumber"
                  filled
                />
              </v-col>
              <v-col>
                <v-masked-text-field
                  label="RFID"
                  v-model="form.rf_id"
                  :mask="$config.inputMasks.rfId"
                  persistent-hint
                  placeholder="Érintse a kártyát az olvasóhoz"
                  filled
                  :error-messages="errors.rf_id"
                  v-uppercase
                  :hint="
                    form.status.name_ && form.status.name_ !== 'printReady' ? 'Nem módosítható' : ''
                  "
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col v-if="form.element_id != 0">
                <v-text-field
                  label="Státusz"
                  :value="form.status.name"
                  readonly
                  hint="Nem módosítható"
                  persistent-hint
                  filled
                />
              </v-col>
              <v-col v-show="hasFeature('CARD_TEMPLATE')">
                <v-autocomplete
                  label="Sablon"
                  v-model="form.template_id"
                  :items="cardTemplates"
                  item-text="name"
                  item-value="id"
                  @change="showPrintIcon = false"
                  :readonly="form.status.name_ && form.status.name_ !== 'printReady'"
                  :error-messages="errors.template_id"
                  :hint="
                    form.status.name_ && form.status.name_ !== 'printReady' ? 'Nem módosítható' : ''
                  "
                  persistent-hint
                  filled
                />
              </v-col>
            </v-row>
            <!-- <v-text-field
              :label="
                form.status === 'idReading'
                  ? 'RF ID (Beolvasáshoz érintse a kártyát az olvasóhoz)'
                  : 'RF ID'
              "
              :error-messages="errors.rf_id"
              v-model="form.rf_id"
              readonly
              :disabled="form.status != 'idReading'"
              v-if="['active', 'tempInactive', 'permInactive', 'idReading'].includes(form.status)"
              filled
            /> -->
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="12" :md="6">
        <v-card>
          <v-card-title><v-icon left>mdi-security</v-icon>Hozzáférési szintek</v-card-title>
          <v-multi-select-list
            :headers="[
              { text: 'Megnevezés', value: 'name' },
              { text: 'Lejárat', value: 'valid_to' },
            ]"
            :items="accessLevels"
            v-model="form.access_levels"
            item-value="element_id"
            item-text="name"
            unique-values
            :blank-item-template="{ element_id: 0 }"
          >
            <template #[`item.name`]="{ item, items }">
              <v-autocomplete
                :items="items"
                v-model="item.element_id"
                item-text="name"
                item-value="element_id"
                placeholder="Válassz"
                hide-details
                filled
                dense
              />
            </template>
            <template #[`item.valid_to`]="{ item }">
              <v-datetime-picker
                v-model="item.valid_to"
                :time-picker="false"
                hide-details
                dense
                placeholder="Nincs lejárat"
                filled
              />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="6">
        <v-card>
          <v-card-title><v-icon left>mdi-car</v-icon>Rendszámok</v-card-title>
          <v-multi-select-list
            :headers="[{ text: 'Rendszám', value: 'name' }]"
            :items="form.plates"
            v-model="form.plates"
            item-value="number"
            item-text="number"
            hide-default-header
            :blank-item-template="{ value: '' }"
          >
            <template #[`item.name`]="{ item, index }">
              <v-masked-text-field
                :label="`#${item.id || index + 1}`"
                v-model="item.number"
                :error-messages="errors[`plate-${index}`]"
                filled
                dense
                :hide-details="!item.active_season_ticket"
                v-uppercase
                :mask="$config.inputMasks.plateNumber"
              />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col>
      <!-- <v-col :cols="12" :md="6" :lg="6">
        <v-card>
          <v-card-title><v-icon left>mdi-shape-plus-outline</v-icon>Extra mezők</v-card-title>
          <v-multi-select-list
            :headers="[
              { text: 'Mező neve', value: 'key' },
              { text: 'Mező értéke', value: 'value' },
            ]"
            :items="[]"
            v-model="form.extra_fields"
            :blank-item-template="{ key: '', value: '' }"
          >
            <template #[`item.key`]="{ item }">
              <v-text-field v-model="item.key" hide-details filled dense placeholder="Név" />
            </template>
            <template #[`item.value`]="{ item }">
              <v-text-field v-model="item.value" hide-details filled dense placeholder="Érték" />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-title><v-icon left>mdi-history</v-icon>Eseménynapló</v-card-title>
          <v-card-text>
            <event-log
              v-if="form.element_id"
              :log="form.log"
              :access-levels="accessLevels"
              :card-statuses="cardStatuses"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import print from 'print-js';
import EventLog from '@/components/CardEventLog.vue';
import { cardReader } from '@/mixins';

export default {
  mixins: [cardReader],

  components: {
    EventLog,
  },

  data() {
    return {
      form: {
        status: 'idReading',
        owner: {},
        access_levels: [],
      },
      formSnapshot: null,
      newAccessLevelId: 0,
      newAccessLevels: [],
      cardOwners: [],
      logs: [],
      accessLevels: [],
      cardStatuses: [],
      defaultStatus: {},
      cardAccessLevelsGroups: [],
      cardTemplates: [],
      defaultTemplate: {},
      showPrintIcon: true,
      parkingPenalty: {
        start_date: '',
        interval: '1 MONTH',
        reason: '',
      },
      errors: {},
      loading: false,
      socket: '',
      headers: [
        { text: 'Adminisztrátor', value: 'writer_name', sortable: false },
        { text: 'Módosítva', value: 'created', sortable: false },
        { value: 'v', sortable: false },
      ],
    };
  },

  beforeDestroy() {
    this.closeCardReaderSocket();
  },

  mounted() {
    this.watchCardReader(async (event) => {
      if (event.data) {
        console.log(event.data);
        if (event.data === 'NO_READERS') {
          const result = this.$dialog.error({
            text: 'A kártyaolvasó nem található. Kérjük ellenőrizze a csatlakozást, vagy jelentse a karbantartónak!',
            title: 'Figyelem!',
            color: 'error',
          });
          return;
        }
        const result = await this.$dialog.confirm({
          text: 'A megfelelő kártyát érintette az olvasóhoz?',
          title: 'Figyelem!',
          color: 'primary',
        });
        if (result) {
          this.form.rf_id = event.data.substr(event.data.length - 8);
        }
      }
    });
    this.fetchCard();
  },

  methods: {
    setAccessLevelsFromGroup(group_id) {
      this.form.access_level_ids = [];
      this.cardAccessLevelsGroups.map((item) => {
        if (item.group_id == group_id) {
          this.form.access_level_ids = JSON.parse(JSON.stringify(item.access_level_ids));
        }
      });

      //this.setNewAccessLevels();
    },

    async fetchCard() {
      this.loading = true;
      try {
        const response = await this.$http.get(`cards/details/${this.$route.params.id || 0}`);
        this.form = response.card;
        if (this.$route.params.ownerId) {
          this.form.owner_id = this.$route.params.ownerId;
        }
        this.formSnapshot = JSON.stringify(this.form);
        this.accessLevels = response.access_levels;
        this.cardStatuses = response.card_statuses;
        this.defaultStatus = response.default_status;
        this.cardOwners = response.card_owners;
        //this.setNewAccessLevels();
        this.cardAccessLevelsGroups = response.card_access_levels_groups;
        this.cardTemplates = response.card_templates;
        this.defaultTemplate = response.default_template;
        this.form.template_id = this.defaultTemplate.id;
        this.form.card_number = this.form.card_number || response.next_card_number;
        if (this.form.status === 'printReady') {
          this.showPrintIcon = true;
        }
        // if (this.form.element_id) {
        //   // this.getLatestLogs();
        //   //this.getLatestParkingPenalties();
        //   if (this.form.status === 'idReading') this.watchCardReader();
        // }
        if (this.form.element_id === 0 && !this.$route.params.id) {
          this.form.owner.element_id = this.$route.params.id;
        }
        this.formSnapshot = this.lodash.cloneDeep(this.form);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    /*setNewAccessLevels() {
      var _this = this;
      this.newAccessLevels = [];
      this.accessLevels.map(function (al) {
        if (_this.form.access_level_ids.indexOf(al.value) < 0)
          _this.newAccessLevels.push(al);
      });
      setTimeout(function () {
        if (_this.$refs["newAccessLevelId"] != undefined)
          _this.$refs["newAccessLevelId"].reload();
      }, 100);
    },*/

    async getLatestParkingPenalties(limit) {
      if (limit === undefined) {
        limit = 10;
      }
      try {
        const data = {
          limit,
          params: `"card_id":${this.$route.params.id}`,
          card_id: this.$route.params.id,
          active: 1,
        };
        const response = await this.$http.post('parking_penalties/', data);
        this.parking_penalties = response.parking_penalties;
        for (const penalty of this.parking_penalties) {
          penalty.editing = false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async saveParkingPenalty(penalty) {
      this.errors = {};
      try {
        let data = {
          card_id: this.$route.params.id,
          start_date: penalty.start_date,
          interval: penalty.interval,
          reason: penalty.reason,
        };
        const response = await this.$http.post('parking_penalties/save/', data);
        if (response.errors) {
          this.errors = response.errors;
        } else {
          this.getLatestParkingPenalties();
          this.parkingPenalty = {
            start_date: '',
            interval: '1 MONTH',
            reason: '',
          };
        }
      } catch (e) {
        console.error(e);
      }
    },

    async editParkingPenalty(data) {
      this.errors = [];
      try {
        // const datapost = {
        //   id: data.id,
        //   start_date: data.start_date,
        //   end_date: data.end_date,
        //   reason: data.reason,
        // };
        const response = await this.$http.post('parking_penalties/edit/');
        if (response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.getLatestParkingPenalties();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async deleteParkingPenalty(id) {
      try {
        const data = {
          id,
        };
        await this.$http.post('parking_penalties/delete/', data);
        this.getLatestParkingPenalties();
      } catch (e) {
        console.error(e);
      }
    },

    async save() {
      // for (const [index, plate] of this.form.plates.entries()) {
      //   plate.input_id = `plate-${index}`;
      // }

      const data = this.lodash.cloneDeep(this.form);
      delete data.log;
      delete data.status;
      data.deleted_access_level_ids = this.lodash
        .differenceWith(
          this.formSnapshot.access_levels,
          this.form.access_levels,
          (a, b) => a.element_id === b.element_id
        )
        .map((e) => e.element_id);
      data.updated_access_levels = this.lodash.differenceWith(
        this.form.access_levels,
        this.formSnapshot.access_levels,
        (a, b) => a.element_id === b.element_id && a.valid_to === b.valid_to
      );
      data.deleted_plates = this.lodash.differenceWith(
        this.formSnapshot.plates,
        this.form.plates,
        (a, b) => a.id === b.id
      );

      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('cards/save', data);
        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }

        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewCard') {
            this.$router.push({ name: 'CardEditor', params: { id: response.card.element_id } });
          } else {
            this.fetchCard();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async print() {
      this.loading = true;
      try {
        const response = await this.$http.post('card/print/', {
          card_id: this.form.element_id,
        });
        printJS(`generated_cards/${response.filename}`);
        const result = await this.$dialog.confirm({
          text: 'Sikerült a kártyanyomtatás?',
          title: 'Figyelem!',
          color: 'primary',
        });
        if (result) {
          this.activate(true);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async suspend() {
      const result = await this.$dialog.confirm({
        text: 'Biztosan fel szeretné függeszteni a kártyát?',
        title: 'Figyelem!',
        color: 'primary',
      });
      if (result) {
        this.loading = true;
        try {
          const data = {
            element_id: this.form.element_id,
          };
          await this.$http.post('cards/suspend', data);
          this.fetchCard();
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },

    async deactivate() {
      const result = await this.$dialog.confirm({
        text: 'Ez a művelet törli a kártya hozzáférési szintjeit, ezáltal nem visszavonható. Folytatja?',
        title: 'Figyelem!',
        color: 'error',
      });
      if (result) {
        this.loading = true;
        try {
          const data = {
            element_id: this.form.element_id,
          };
          await this.$http.post('cards/deactivate', data);
          this.fetchCard();
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },

    async activate(skipDialog = false) {
      const result =
        skipDialog ||
        (await this.$dialog.confirm({
          text: 'Biztosan aktiválni szeretné a kártyát?',
          title: 'Figyelem!',
          color: 'primary',
        }));
      if (result) {
        this.loading = true;
        try {
          const data = {
            element_id: this.form.element_id,
          };
          await this.$http.post('cards/activate', data);
          this.fetchCard();
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },
  },

  computed: {
    extraFields() {
      return this.$store.getters['fields/findByModelName']('CardOwners');
    },

    isOwnerIdMutable() {
      return this.$route.name === 'NewCard' || this.hasFeature('CARD_OWNERID_CHANGE');
    },

    assignedAccessLevels() {
      return this.lodash.filter(this.accessLevels, (al) => {
        console.log(this.form.access_level_ids);
        return this.lodash.includes(this.form.access_level_ids, al.element_id);
      });
      // const accessLevels = [];
      // for (const accessLevel of this.accessLevels) {
      //   if (this.form.access_level_ids.includes(accessLevel.element_id)) {
      //     accessLevels.push(accessLevel);
      //   }
      // }
      // console.log(accessLevels);
      // return accessLevels;
    },

    isDirty() {
      return JSON.stringify(this.form) === this.formSnapshot;
    },
  },
};
</script>
